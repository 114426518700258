import { connectionName as componentConnectionName } from '@owc/component-registry-connection';
import { setMainNamespace } from '@seamless/router';
import { addVuePlugins } from './vue-plugins';
import { addStoreConnections, initializePackages, setupEnvironment } from '@owc/globals/helpers';
import { AEMManifest } from './manifest-interface';

declare const VERSION: string;
declare const ENV: string;
const logprefix = `GLOBALS ${VERSION}`;
const prefix = (suffix: string): string => `${logprefix} ${suffix}`;
// eslint-disable-next-line no-undef
export const version = VERSION;
const { store, logger } = setupEnvironment({ namespace: 'OWC FE LIBS NAFTA GLOBALS' });

addStoreConnections({ loggerInstance: logger })
  .then(() => addVuePlugins().catch((e) => Promise.resolve(e)))
  .then((e) => {
    if (e) {
      logger.error(e);
    }
    initializePackages({ loggerInstance: logger, packages: ['ComponentRegistry', 'WkoManager'] });

    /**
     * @todo read the given main routing component by aem checkbox and set the mainnamespace on the router
     **/
    if (store) {
      store.subscribe(componentConnectionName, (state: { [key: string]: any }) => {
        if (state.components) {
          const keys = Object.keys(state.components);
          keys.forEach((key) => {
            const item: AEMManifest = state.components[key];
            // sometimes booleans are not booleans
            if (item.config && item.config.routingEnabled) {
              logger.log(prefix(`main namespace found ${item.config.routerNamespace}`));
              setMainNamespace(item.config.routerNamespace as string);
            }
          });
        }
      });
    }
  })
  .catch((e) => logger.error(e));
