import VueSeamlessStore from '@seamless/vue-store-plugin';
// import VueI18n from 'vue-i18n';
declare global {
  interface Window {
    Vue: any;
  }
}
/**
 *
 * @returns Promise<boolean | Error>
 */
export const addVuePlugins = async (): Promise<boolean | Error> => {
  if (window.Vue) {
    window.Vue.use(VueSeamlessStore);
    // window.Vue.use(VueI18n);
    return false;
  }
  return new Error('Vue seems not defined on window');
};
